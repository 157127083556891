<template>
    <div class="block block--faq">
        <div class="card block__card">
        <div class="block__content">
            <h3 class="block__title">{{ $j("chavoTitle") }}</h3>
            <div class="custom-accordion accordion" id="faq" role="tree">
                <div class="custom-accordion__item accordion-item">
                    <div class="custom-accordion__header accordion-header" id="heading1" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">{{ $j("chavo1") }}
                    </div>
                    <div class="accordion-collapse collapse custom-accordion-collapse" id="collapse1" aria-labelledby="heading1" data-bs-parent="#faq">
                    <div class="custom-accordion__body">
                        <p class="text-start">
                            <span v-html="$j('chavoText1')"></span>
                        </p>
                    </div>
                    </div>
                </div>
                <div class="custom-accordion__item accordion-item">
                    <div class="custom-accordion__header accordion-header" id="heading2" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">{{ $j("chavo2") }}
                    </div>
                    <div class="accordion-collapse collapse custom-accordion-collapse" id="collapse2" aria-labelledby="heading2" data-bs-parent="#faq">
                    <div class="custom-accordion__body">
                        <p class="text-start">
                            <span v-html="$j('chavoText2')"></span>
                        </p>
                    </div>
                    </div>
                </div>
                <div class="custom-accordion__item accordion-item">
                    <div class="custom-accordion__header accordion-header" id="heading3" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">{{ $j("chavo3") }}
                    </div>
                    <div class="accordion-collapse collapse custom-accordion-collapse" id="collapse3" aria-labelledby="heading3" data-bs-parent="#faq">
                    <div class="custom-accordion__body">
                        <p class="text-start">
                            <span v-html="$j('chavoText3')"></span>
                        </p>
                    </div>
                    </div>
                </div>
                <div class="custom-accordion__item accordion-item">
                    <div class="custom-accordion__header accordion-header" id="heading4" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">{{ $j("chavo4") }}
                    </div>
                    <div class="accordion-collapse collapse custom-accordion-collapse" id="collapse4" aria-labelledby="heading4" data-bs-parent="#faq">
                    <div class="custom-accordion__body">
                        <p class="text-start">
                            <span v-html="$j('chavoText4')"></span>
                        </p>
                    </div>
                    </div>
                </div>
                <div class="custom-accordion__item accordion-item">
                    <div class="custom-accordion__header accordion-header" id="heading5" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">{{ $j("chavo5") }}
                    </div>
                    <div class="accordion-collapse collapse custom-accordion-collapse" id="collapse5" aria-labelledby="heading5" data-bs-parent="#faq">
                    <div class="custom-accordion__body">
                        <p class="text-start">
                            <span v-html="$j('chavoText5')"></span>
                        </p>
                    </div>
                    </div>
                </div>
                <div class="custom-accordion__item accordion-item">
                    <div class="custom-accordion__header accordion-header" id="heading6" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">{{ $j("chavo6") }}
                    </div>
                    <div class="accordion-collapse collapse custom-accordion-collapse" id="collapse6" aria-labelledby="heading6" data-bs-parent="#faq">
                    <div class="custom-accordion__body">
                        <p class="text-start">
                            <span v-html="$j('chavoText6')"></span>
                        </p>
                    </div>
                    </div>
                </div>
                <div class="custom-accordion__item accordion-item">
                    <div class="custom-accordion__header accordion-header" id="heading7" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">{{ $j("chavo7") }}
                    </div>
                    <div class="accordion-collapse collapse custom-accordion-collapse" id="collapse7" aria-labelledby="heading7" data-bs-parent="#faq">
                    <div class="custom-accordion__body">
                        <p class="text-start">
                            <span v-html="$j('chavoText7')"></span>
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
};
</script>